// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vison-servicesSection {
  background-color: #c6d1d3;
  padding: 1em;
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 13em;
}

.rectangle1,
.rectangle2 {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3em;
  box-sizing: border-box;
}

.rectangle1 {
  left: 4em;
  color: #000;
  z-index: 2;
  /* background-color: #8a9a9dce; */
  background-color: #88aa99 !important;

  box-shadow: rgb(154, 158, 161) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset,
    rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.rectangle2 {
  top: 12em;
  color: #000;
  /* background-color: #8a9a9dce; */
  background-color: #88aa99 !important;

  box-shadow: rgb(154, 158, 161) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset,
    rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

h3 {
  text-align: center;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .vison-sericesSection {
    padding-top: 20em !important;
    margin-bottom: 1em !important;
  }

  .rectangle1 {
    left: 0;
  }

  .rectangle2 {
    left: 0;
    top: 2em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/PAGES/HOME/SECTIONS/VISION_BUSINESS/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,8BAA8B;EAC9B,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,UAAU;EACV,iCAAiC;EACjC,oCAAoC;;EAEpC;;yCAEuC;AACzC;;AAEA;EACE,SAAS;EACT,WAAW;EACX,iCAAiC;EACjC,oCAAoC;;EAEpC;;yCAEuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE;IACE,4BAA4B;IAC5B,6BAA6B;EAC/B;;EAEA;IACE,OAAO;EACT;;EAEA;IACE,OAAO;IACP,QAAQ;EACV;AACF","sourcesContent":[".vison-servicesSection {\n  background-color: #c6d1d3;\n  padding: 1em;\n  /* height: 100%; */\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding-bottom: 13em;\n}\n\n.rectangle1,\n.rectangle2 {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  padding: 3em;\n  box-sizing: border-box;\n}\n\n.rectangle1 {\n  left: 4em;\n  color: #000;\n  z-index: 2;\n  /* background-color: #8a9a9dce; */\n  background-color: #88aa99 !important;\n\n  box-shadow: rgb(154, 158, 161) 3px 3px 6px 0px inset,\n    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset,\n    rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;\n}\n\n.rectangle2 {\n  top: 12em;\n  color: #000;\n  /* background-color: #8a9a9dce; */\n  background-color: #88aa99 !important;\n\n  box-shadow: rgb(154, 158, 161) 3px 3px 6px 0px inset,\n    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset,\n    rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;\n}\n\nh3 {\n  text-align: center;\n  margin-bottom: 1em;\n}\n\n@media (max-width: 768px) {\n  .vison-sericesSection {\n    padding-top: 20em !important;\n    margin-bottom: 1em !important;\n  }\n\n  .rectangle1 {\n    left: 0;\n  }\n\n  .rectangle2 {\n    left: 0;\n    top: 2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
